<template>
    <el-upload
        class="center-member-works-upload"
        action="#"
        list-type="picture"
        :show-file-list="false"
        :auto-upload="false"
        :multiple="false"
        :on-change="handleChange">
        <el-image
            :src="image_url"
            v-if="image_url"
            fit="scale-down"
            lazy
            class="works-upload">
            <!-- 加载中样式 -->
            <template v-slot:placeholder>
                <div class="image-slot">
                    <i class="el-icon-loading"></i>
                </div>
            </template>
            <!-- 加载失败样式 -->
            <template v-slot:error>
                <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </template>
        </el-image>
        <i v-else slot="default" class="el-icon-plus works-upload-icon"></i>
    </el-upload>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            image_id: "",
            image_url: "",
        }
    },
    props: [ "styleId", "imageId", "imageUrl" ],
    methods: {
        ...mapActions([
            "handleUploadWorks"
        ]),
        setImage(){
            this.image_id = this.imageId;
            this.image_url = this.imageUrl;
        },
        handleBASE64(file){
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.onload = () => {
                    let _base64 = reader.result;
                    let BASE64 = _base64.split(",");
                    resolve(BASE64[1])
                }
                reader.readAsDataURL(file)
            })
        },
        handleChange(file, fileList){
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return ;
            }
            let type = this.$route.name == "centerLensmanInfo"? 1: 2;
            this.handleBASE64(file.raw)
                .then((res) => {
                    this.handleUploadWorks({
                        type,
                        styleID: this.styleId,
                        image: res,
                        mid: this.image_id
                    }).then((res) => {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.type? "success": "error"
                        })
                    })
                })
        }
    },
    watch: {
        imageUrl: {
            handler: "setImage",
            immediate: true
        }
    },
}
</script>

<style scoped>
.center-member-works-upload{
    position: relative;
    width: 100%;
}
.center-member-works-upload::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.center-member-works-upload>>>.el-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 2px solid #8d9afc;
    border-radius: 6px;
    overflow: hidden;
}
.works-upload-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 28px;
    color: #8d9afc;
}
.works-upload{
    width: 100%;
    height: 100%;
}
</style>